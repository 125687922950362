const LoadingSpinner = () => {
  return <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
              viewBox="0 0 24 24">
    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"/>
    <path className="opacity-75" fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"/>
  </svg>
}

const Button = ({ onClick, children, disabled, isLoading }) => {
  let classes = 'flex bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full'

  if (disabled) {
    classes += ' opacity-50 cursor-not-allowed'
  }

  return (
    <button
      onClick={onClick}
      className={classes}
      type="button"
      disabled={disabled || isLoading}
    >
      {isLoading && <LoadingSpinner/>}
      {children}
    </button>
  )
}

export default Button
