import { toPng } from 'dom-to-image-retina'
import JSZip from 'jszip'
import { saveAs } from 'file-saver'

export const downloadElements = (elements) => {
  const zip = new JSZip()
  const date = new Date()
  const formatted = `${date.getFullYear()}_${date.getMonth()}_${date.getDate()}__${date.getHours()}_${date.getMinutes()}`
  const imagesFolder = zip.folder(`cards_${formatted}`)

  return Promise.all(elements.map(async (cardEl, idx) => {
    return toPng(cardEl)
      .then(imageDataUrl => {
        return imagesFolder.file(
          `card_${idx}_${formatted}.png`,
          imageDataUrl.substr(imageDataUrl.indexOf(',') + 1),
          { base64: true }
        )
      })
  }))
    .then(() => zip.generateAsync({ type: 'blob' }))
    .then(zipFile => saveAs(zipFile, `dobble_${formatted}.zip`))

}
