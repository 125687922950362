import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import Button from '../Button'

const Thumbnail = ({ name, path, preview, onRemove }) => (
  <div className="inline-flex relative justify-center shadow-sm mb-4 mr-4 w-32 h-32 border p-2 rounded-md" key={name}>
    <button onClick={() => onRemove(path)}
            className="cursor-pointer absolute right-0.5 flex items-center justify-center top-0.5 text-red-700 text-lg hover:bg-red-200 w-6 h-6 leading-none rounded">
      ✕
    </button>
    <div className="flex min-w-0 overflow-hidden">
      <img
        src={preview}
        className="w-auto h-full block"
        alt={name}
      />
    </div>
  </div>
)

const Uploader = ({
  count,
                    onConfirm = () => {
                    }
                  }) => {
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setFiles([...files, ...acceptedFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      }))])
    }
  })

  const onRemove = useCallback((path) => {
    setFiles(files.filter(file => path !== file.path))
  }, [files])

  useEffect(() => () => {
    files.forEach(file => URL.revokeObjectURL(file.preview))
  }, [files])

  return <section className="mb-8">
    <div className='text-lg mb-2'>Upload {count} images:</div>
    <div {...getRootProps({ className: 'cursor-pointer select-none transition-all ease-in-out duration-100 flex items-center justify-center w-full h-48 border-gray-200 border p-4 rounded-lg text-gray-500 hover:border-gray-300 hover:text-gray-700' })}>
      <input {...getInputProps()} />
      <p>Drag 'n' drop some files here, or click to select files</p>
    </div>
    {Boolean(files.length) &&
    <aside className="flex flex-row flex-wrap mt-4 border-gray-200 border p-4 pb-0 rounded-lg">
      {files.map(file => <Thumbnail
        key={file.path}
        name={file.name}
        path={file.path}
        preview={file.preview}
        onRemove={onRemove}/>
      )}
    </aside>}
    {Boolean(files.length) && <div className="flex justify-end mt-4">
      <Button
        onClick={() => onConfirm(files)}
        disabled={files.length < count}
      >
        Generate
      </Button>
    </div>}
  </section>
}

export default Uploader
