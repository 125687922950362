import { forwardRef, useCallback, useLayoutEffect, useRef, useState } from 'react'
import Moveable from 'react-moveable'
import {
  getRandomizedPositions,
  getRandomizedRotation,
  getRandomizedSize,
  getSizeScale
} from '../utils/placement'
import Button from '../Button'
import { downloadElements } from '../utils/downloader'

const Item = ({ preview, placement, readonly }) => {
  const [target, setTarget] = useState()
  const [frame] = useState({
    translate: [placement.x, placement.y],
    rotate: placement.rotate,
  })
  const targetRef = useRef()

  useLayoutEffect(() => {
    targetRef.current && setTarget(targetRef.current)
  }, [])

  return <div>
    <div ref={targetRef} style={{
      width: `${placement.width}px`,
      height: `${placement.height}px`,
      transform: `translate(${placement.x}px, ${placement.y}px) rotate(${placement.rotate}deg)`,
    }} className="flex min-w-0 overflow-hidden items-center justify-center absolute">
      <img
        draggable={false}
        src={preview}
        className="w-auto h-full block"
        alt=""
      />
    </div>
    {!readonly && <Moveable
      target={target}
      draggable={true}
      throttleDrag={0}
      startDragRotate={0}
      throttleDragRotate={0}
      origin={false}
      zoom={1}
      padding={{ 'left': 0, 'top': 0, 'right': 0, 'bottom': 0 }}
      rotatable={true}
      throttleRotate={0}
      rotationPosition={'top'}
      resizable={true}
      keepRatio={true}
      renderDirections={['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']}
      onDragStart={e => {
        e.set(frame.translate)
      }}
      onDrag={e => {
        frame.translate = e.beforeTranslate
        e.target.style.transform = `translate(${e.beforeTranslate[0]}px, ${e.beforeTranslate[1]}px) rotate(${frame.rotate}deg)`
      }}
      onRotateStart={e => {
        e.set(frame.rotate)
      }}
      onRotate={e => {
        frame.rotate = e.beforeRotate
        e.target.style.transform = `translate(${frame.translate[0]}px, ${frame.translate[1]}px) rotate(${e.beforeRotate}deg)`
      }}
      onRender={e => {
        const { translate, rotate } = frame
        e.target.style.transform = `translate(${translate[0]}px, ${translate[1]}px) rotate(${rotate}deg)`
      }}
      onResizeStart={e => {
        e.dragStart && e.dragStart.set(frame.translate)
      }}
      onResize={e => {
        const beforeTranslate = e.drag.beforeTranslate

        frame.translate = beforeTranslate
        e.target.style.width = `${e.width}px`
        e.target.style.height = `${e.height}px`
        e.target.style.transform = `translate(${beforeTranslate[0]}px, ${beforeTranslate[1]}px) rotate(${frame.rotate}deg)`
      }}
    />}
  </div>
}

const Card = forwardRef(({ images, set, readonly }, ref) => {
  const containerRef = useRef()
  const [dimensions, setDimensions] = useState()
  const [elementsPlacements, setElementsPlacements] = useState()

  useLayoutEffect(() => {
    if (containerRef.current) {
      const parentSize = {
        width: +containerRef.current.clientWidth,
        height: +containerRef.current.clientHeight,
      }

      setDimensions(parentSize)

      const desiredSize = getSizeScale(parentSize.width, parentSize.height, images.length)

      const sizes = images.map(() => {
        const sizes = getRandomizedSize({
          width: parentSize.width / 4,
          height: parentSize.height / 4
        }, desiredSize)
        const rotate = getRandomizedRotation()

        return {
          ...sizes,
          rotate
        }
      })

      setElementsPlacements(getRandomizedPositions(sizes, parentSize))
    }
  }, [setDimensions, images])

  return (
    <div ref={ref}>
      <div ref={containerRef}
           className="bg-white relative rounded-full border border-gray-400 h-96 w-96 m-4 overflow-hidden">
        {dimensions && set.map(index => <Item key={index} readonly={readonly} placement={elementsPlacements[index]}
                                              preview={images[index].preview}/>)}
      </div>
    </div>
  )
})

const Preview = ({ images, series }) => {
  const cardsRefs = useRef([])
  const [isLoading, setIsLoading] = useState(false)
  const [readonly, setReadonly] = useState(false)

  const onDownload = useCallback(() => {
    setIsLoading(true)
    setReadonly(true)

    downloadElements(cardsRefs.current).then(() => {
      setIsLoading(false)
      setReadonly(false)
    })
  }, [cardsRefs])

  return (
    <>
      <div className="h-full mb-4 border-gray-200 border p-4 rounded-lg">
        <div className="flex flex-wrap">
          {series.map((set, index) => <Card ref={ref => cardsRefs.current[index] = ref} key={set.join('_')}
                                            readonly={readonly} images={images} set={set}/>)}
        </div>
      </div>
      <div className="flex justify-end">
        <Button
          onClick={onDownload}
          isLoading={isLoading}
        >
          {isLoading ? 'Downloading...' : 'Download'}
        </Button>
      </div>
    </>
  )
}

export default Preview
