import Header from './Header'
import Uploader from './Uploader'
import Configurator from './Configurator'
import { useCallback, useState } from 'react'
import { generateSeries, getTotalCombinations } from './utils/dobble'
import Preview from './Preview'

function App() {
  const [cardsCount, setCardsCount] = useState()
  const [series, setSeries] = useState()
  const [images, setImages] = useState([])

  const onCardsCountConfirm = useCallback((cards) => {
    setCardsCount(getTotalCombinations(cards))
    setSeries(generateSeries(cards))
  }, [])

  return (
    <>
      <Header/>
      <div className="p-8">
        <Configurator onConfirm={onCardsCountConfirm}/>
        {
          Number.isInteger(cardsCount) &&
          <Uploader count={cardsCount} onConfirm={setImages}/>
        }
        {
          series && Boolean(images.length) &&
          <Preview series={series} images={images}/>
        }
      </div>
    </>
  )
}

export default App
