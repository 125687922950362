export const getSizeScale = (width, height, cards) => Math.floor(((width * height) / cards) * 0.9)

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min

export const getRandomizedSize = (originalSize, desiredSize) => {
  const ratio = originalSize.height / originalSize.width
  let width, height

  width = Math.floor(Math.sqrt(desiredSize / ratio)) * 0.6
  width = width < 75 ? 75 : width
  width = random(65, width * 1.5)

  height = width * ratio
  return { width: width, height: height }
}

export const getRandomizedRotation = () => random(0, 360)

export const getRandomizedPositions = (elementsSizes, parentSize) => {
  return elementsSizes.reduce((elements, elementSize) => {
    let elementDimensions = {
      ...elementSize,
    }

    for (let i = 0, overlapping = true; i < 15 && overlapping; i++) {
      elementDimensions.y = Math.round(Math.random() * (parentSize.width - elementSize.width))
      elementDimensions.x = Math.round(Math.random() * (parentSize.height - elementSize.height))

      overlapping = false

      for (let sibling of elements) {
        if (isOverlapped(elementDimensions, sibling)) {
          overlapping = true
          break
        } else {
          overlapping = false
        }
      }
    }

    return [...elements, elementDimensions]
  }, [])
}

const valueInRange = (value, min, max) => (value >= min) && (value <= max)

const isOverlapped = (first, second) => {
  const overlapX = valueInRange(first.left, second.left, second.left + second.width) ||
    valueInRange(second.left, first.left, first.left + first.width)

  const overlapY = valueInRange(first.top, second.top, second.top + second.height) ||
    valueInRange(second.top, first.top, first.top + first.height)

  return overlapX && overlapY
}
