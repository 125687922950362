import { useState } from 'react'
import Button from '../Button'

const preset = [
  2,
  3,
  4,
  6,
  8,
  12,
  14,
  18,
  20,
  24,
  30,
  32
]

const Configurator = ({ onConfirm }) => {
  const [selected, setSelected] = useState()

  const onSelectedChange = (e) => setSelected(Number.parseInt(e.target.value))

  return (
    <div className="h-full mb-8 flex items-center border-gray-200 border p-4 rounded-lg">
      <div className="flex-grow flex items-center justify-between">
        <div className="flex items-center">
          Cards:
          <div className="ml-4 inline-flex relative w-64">
            <select defaultValue onChange={onSelectedChange}
                    className="block appearance-none w-full bg-white border border-gray-200 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
              <option disabled value>Number of cards</option>
              {
                preset.map(value => <option key={value} value={value}>{value}</option>)
              }
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
        </div>
        <Button
          onClick={() => onConfirm(selected)}
          disabled={!Number.isInteger(selected)}
        >
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default Configurator
