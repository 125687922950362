export const getTotalCombinations = (n) => Math.pow(n - 1, 2) + (n - 1) + 1

export const generateSeries = (cards) => {
  const p = cards - 1
  const series = []

  for (let i = 0; i < p; i++) {
    const set = []
    set.push(p * p)

    for (let j = 0; j < p; j++) {
      set.push(i * p + j)
    }

    series.push(set)
  }

  for (let i = 0; i < p; i++) {
    for (let j = 0; j < p; j++) {
      const set = []
      set.push(p * p + 1 + i)

      for (let k = 0; k < p; k++) {
        set.push(k * p + (j + i * k) % p)
      }

      series.push(set)
    }
  }

  const set = []

  for (let i = 0; i < p + 1; i++) {
    set.push(p * p + i)
  }

  series.push(set)

  return series
}
