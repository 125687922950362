import logo from './logo.svg'

const Header = () => {
  return (
    <header className="text-gray-600 body-font shadow">
      <div className="mx-auto flex flex-wrap px-5 py-1 flex-col md:flex-row items-center">
        <a href='/' className="flex title-font font-medium items-center text-gray-900">
          <img src={logo} className="w-8 my-0.5" alt="logo"/>
          <span className="ml-3 text-xl">Dobble Generator</span>
        </a>
      </div>
    </header>
  )
}

export default Header
